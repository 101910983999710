import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import "vue-pure-lightbox/dist/VuePureLightbox.css";

@Component
export default class DfCampaignCardComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  campaign!: DfContent;

  private showGallery = false;
  private galleryIndex = 0;

  get preview(): string {
    return Utils.getPropertyImageUrl(this.campaign, Utils.PROPERTY_PREVIEW, null);
  }

  get url(): string {
    const propertyUrl: string = Utils.getPropertyValue(this.campaign, Utils.PROPERTY_URL, "STRING", "");
    return propertyUrl && !propertyUrl.startsWith("http") && !propertyUrl.startsWith("/") ? `/${propertyUrl}` : propertyUrl;
  }

  get buttonLabel(): string {
    return Utils.getPropertyValue(this.campaign, Utils.PROPERTY_DETAILS_BUTTON, "STRING", this.$t("df-campaign-card.button-label").toString(), true);
  }

  get gallery(): Array<any> {
    return Utils.getPropertyImagesUrl(this.campaign, Utils.PROPERTY_GALLERY).map((imageUrl: string) => {
      return imageUrl;
    });
  }

  private onClick() {
    if (this.url) {
      Utils.isExternalLink(this.url) ? window.open(this.url, "_blank") : this.$router.push({ path: this.url });
    } else if (this.gallery.length > 0) {
      this.galleryIndex = 0;
      this.showGallery = true;
    }
  }

  private nextImage() {
    this.galleryIndex = this.galleryIndex === this.gallery.length - 1 ? 0 : this.galleryIndex + 1;
  }

  private previousImage() {
    this.galleryIndex = this.galleryIndex === 0 ? this.gallery.length - 1 : this.galleryIndex - 1;
  }
}